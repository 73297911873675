.input__number {
  border: 1px solid $color-middle-gray;
  border-radius: 5px;
  padding: 10px;
  width: 100%;

  &:focus {
    outline: 0;
  }
}

::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-input-placeholder {
  color: $color-middle-gray;
}
