.btn {
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-block;
  font-size: $font-size-small;
  font-weight: 400;
  outline: none;
  padding: $spacing-0 $spacing-2;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
}

.btn--primary {
  background-color: $color-dark-gray;
  border-color: $color-dark-gray;
  color: $color-light;
  opacity: 0.8;

  .icon {
    color: $color-light;
  }

  &:hover {
    opacity: 1;
  }

  &.disabled,
  &.disabled:hover {
    opacity: 0.5;
  }
}

.btn--link {
  background-color: transparent;
  color: $color-dark-gray;
  font-size: $font-size-sallest;
  opacity: 0.8;
  padding: 0;

  &:hover {
    opacity: 1;
  }
}
