@charset "UTF-8";
@font-face {
  font-family: 'fontello';
  src: url("../font/fontello.eot?19847152");
  src: url("../font/fontello.eot?19847152#iefix") format("embedded-opentype"), url("../font/fontello.woff2?19847152") format("woff2"), url("../font/fontello.woff?19847152") format("woff"), url("../font/fontello.ttf?19847152") format("truetype"), url("../font/fontello.svg?19847152#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?19847152#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-chat:before {
  content: '\a8d5'; }

/* '꣕' */
.icon-doc:before {
  content: '\b258'; }

/* '뉘' */
.icon-arrows-cw:before {
  content: '\e7e9'; }

/* '' */
.icon-split:before {
  content: '\e7fa'; }

/* '' */
.icon-attention-alt:before {
  content: '\e7fe'; }

/* '' */
.icon-attention-alt:before {
  content: '\e7ff'; }

/* '' */
.icon-phone:before {
  content: '\e800'; }

/* '' */
.icon-pin-outline:before {
  content: '\e801'; }

/* '' */
.icon-left:before {
  content: '\e802'; }

/* '' */
.icon-down-open:before {
  content: '\e802'; }

/* '' */
.icon-emo-unhappy:before {
  content: '\e803'; }

/* '' */
.icon-chat:before {
  content: '\e804'; }

/* '' */
.icon-search:before {
  content: '\e805'; }

/* '' */
.icon-clock:before {
  content: '\e806'; }

/* '' */
.icon-eye:before {
  content: '\e807'; }

/* '' */
.icon-attach:before {
  content: '\e808'; }

/* '' */
.icon-cancel:before {
  content: '\e809'; }

/* '' */
.icon-headphones:before {
  content: '\e80a'; }

/* '' */
.icon-signal:before {
  content: '\e80b'; }

/* '' */
.icon-stop:before {
  content: '\e80c'; }

/* '' */
.icon-power:before {
  content: '\e80d'; }

/* '' */
.icon-exchange:before {
  content: '\e80e'; }

/* '' */
.icon-th:before {
  content: '\e80f'; }

/* '' */
.icon-down:before {
  content: '\e810'; }

/* '' */
.icon-double-check:before {
  content: '\e811'; }

/* '' */
.icon-check:before {
  content: '\e812'; }

/* '' */
.icon-sent:before {
  content: '\e813'; }

/* '' */
.icon-spin2:before {
  content: '\e831'; }

/* '' */
.icon-spin5:before {
  content: '\e838'; }

/* '' */
.icon-right-open:before {
  content: '\f006'; }

/* '' */
.icon-logout-1:before {
  content: '\f02d'; }

/* '' */
.icon-mic-off:before {
  content: '\f047'; }

/* '' */
.icon-mic:before {
  content: '\f048'; }

/* '' */
.icon-link-ext:before {
  content: '\f08e'; }

/* '' */
.icon-list-bullet:before {
  content: '\f0ca'; }

/* '' */
.icon-comment-empty:before {
  content: '\f0e5'; }

/* '' */
.icon-circle-empty:before {
  content: '\f10c'; }

/* '' */
.icon-doc-inv:before {
  content: '\f15b'; }

/* '' */
.icon-dot-circled:before {
  content: '\f192'; }

/* '' */
.icon-list:before {
  content: '𕼔'; }

/* '\15f14' */
.icon-chat-alt:before {
  content: '𘇍'; }

/* '\181cd' */
