@import './variables/index.scss';
@import './utils/fontello.css';
@import './utils/animation.css';
@import 'form.scss';
@import 'button.scss';
@import 'scrollbar.scss';
@import 'colors.scss';

* {
  box-sizing: border-box;
  color: $color-black;
  font-family: $font-primary;
}

h1,
h4 {
  font-family: $font-primary;
  margin: 0;
}

a {
  color: $color-link;
}

body {
  background-color: #f9fafb;
  margin: 0;
}

.icon-phone-hangup::before {
  transform: rotate(136deg);
}
